(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/helpers/lazy-config.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/helpers/lazy-config.js');
"use strict";


const setLazyConfig = () => {
  if (window.lazySizes) {
    window.lazySizes.cfg.nativeLoading = {
      setLoadingAttribute: true,
      disableListeners: {
        scroll: true
      }
    };
  }
};
svs.content.image_v2.helpers.setLazyConfig = setLazyConfig;

 })(window);