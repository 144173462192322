(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/helpers/multi-image-style-element.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/helpers/multi-image-style-element.js');
"use strict";


const {
  toArrayOrNull
} = svs.content.image_v2.helpers;

const setStyleElement = (contentId, images) => {
  const text = {
    styleTagId: 'image-v2',
    prefix: 'Styles for'
  };
  const head = document.querySelector('head');

  const getMediaQueriesString = (image, selector) => {
    const {
      aspectRatio,
      dominantColor,
      media
    } = image || {};
    const declarations = [aspectRatio && "padding-bottom: ".concat(aspectRatio.height / aspectRatio.width * 100, "%;"), dominantColor && "background-color: ".concat(dominantColor, ";")];
    const declarationsString = declarations.filter(item => Boolean(item)).join('\n\t\t');
    return "@media only screen and ".concat(media, " {") + "\n\t.".concat(selector, " {") + "\n\t\t".concat(declarationsString, "\n\t") + "}\n}";
  };

  const hasMediaQueries = images => (images === null || images === void 0 ? void 0 : images.some(item => item && 'media' in item && 'aspectRatio' in item)) || false;

  const arrayToCssString = input => {
    const output = input === null || input === void 0 ? void 0 : input.map(_ref => {
      let {
        id,
        content
      } = _ref;
      const cssComment = "/* ".concat(text.prefix, " ").concat(id, " */\n\n");
      const style = "".concat(content, "\n\n");
      return "".concat(cssComment).concat(style).trim();
    });
    return (output === null || output === void 0 ? void 0 : output.join('\n\n')) || '';
  };

  const cssStringToArray = input => {
    const regex = new RegExp("\\/\\*\\s".concat(text.prefix, "(.*?)\\s\\*\\/([\\s\\S]*?)(?=\\/\\*\\s").concat(text.prefix, "|$)"), 'g');
    const matches = input.match(regex);
    const styles = matches.map(match => {
      regex.lastIndex = 0;
      const match2 = regex.exec(match) || [];
      const [, id, content] = match2;
      return {
        id: id === null || id === void 0 ? void 0 : id.trim(),
        content: content === null || content === void 0 ? void 0 : content.trim()
      };
    }) || [];
    return styles;
  };

  const getStyleElement = id => {
    const existingElement = head.querySelector("#".concat(id));
    const hasExistingElement = Boolean(existingElement);
    const styleElement = hasExistingElement ? existingElement : document.createElement('style');
    return [styleElement, hasExistingElement];
  };
  const init = () => {
    const imagesArray = toArrayOrNull(images);
    if (!hasMediaQueries(imagesArray)) {
      return;
    }
    const cssDeclarationString = imagesArray.map(image => getMediaQueriesString(image, contentId)).join('\n');
    const [styleElement, hasExistingStyleElement] = getStyleElement(text.styleTagId);
    if (hasExistingStyleElement) {
      const cssArray = cssStringToArray(styleElement.textContent);
      const thisIdIndex = cssArray.findIndex(item => item.id === contentId);
      const newValue = {
        id: contentId,
        content: cssDeclarationString
      };
      if (thisIdIndex !== -1) {
        cssArray[thisIdIndex] = newValue;
      } else {
        cssArray.push(newValue);
      }
      const cssString = arrayToCssString(cssArray);
      styleElement.textContent = cssString;
      return;
    }

    styleElement.id = text.styleTagId;
    const cssArray = [{
      id: contentId,
      content: cssDeclarationString
    }];
    styleElement.textContent = arrayToCssString(cssArray);
    head.appendChild(styleElement);
  };
  init();
};


const setupForImageElement = element => {
  var _element$dataset;
  const id = element === null || element === void 0 || (_element$dataset = element.dataset) === null || _element$dataset === void 0 ? void 0 : _element$dataset.multiImageId;
  if (id) {
    const dataString = element.dataset.multiImageDataString;
    const data = JSON.parse(dataString);
    setStyleElement(id, data);
    delete element.dataset.multiImageDataString;
    delete element.dataset.multiImageId;
  }
};
svs.content.image_v2.helpers.multiImageStyleElement = {
  setupForImageElement,
  setStyleElement
};

 })(window);