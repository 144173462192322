(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/helpers/to-array-or-null.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/helpers/to-array-or-null.js');
"use strict";


const toArrayOrNull = input => {
  if (!input || typeof input !== 'object') {
    return null;
  }
  const result = [];
  if (typeof input === 'object' && input !== null) {
    if (Array.isArray(input)) {
      result.push(...input);
    } else {
      for (const key in input) {
        if (Object.prototype.hasOwnProperty.call(input, key)) {
          result.push(input[key]);
        }
      }
    }
  }
  return result;
};
if (typeof exports === 'object') {
  module.exports = {
    toArrayOrNull
  };
} else {
  svs.content.image_v2.helpers.toArrayOrNull = toArrayOrNull;
}

 })(window);