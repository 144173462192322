(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/views/multi-image.js') >= 0) return;  svs.modules.push('/components/content/image-v2/views/multi-image.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.content=_svs.content||{};
_svs.content.image_v2=_svs.content.image_v2||{};
_svs.content.image_v2.templates=_svs.content.image_v2.templates||{};
svs.content.image_v2.templates.multi_image = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " background-image-wrapper image-wrapper-fit";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"cssSelectors") || (depth0 != null ? lookupProperty(depth0,"cssSelectors") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cssSelectors","hash":{},"data":data,"loc":{"start":{"line":3,"column":146},"end":{"line":3,"column":164}}}) : helper))) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"unique-id") || (depth0 != null ? lookupProperty(depth0,"unique-id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"unique-id","hash":{},"data":data,"loc":{"start":{"line":3,"column":189},"end":{"line":3,"column":204}}}) : helper))) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"getSourceElementsData")||(depth0 && lookupProperty(depth0,"getSourceElementsData"))||container.hooks.helperMissing).call(alias1,depth0,{"name":"getSourceElementsData","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":37}}}),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":14,"column":13}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <source "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dataSrc") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":68}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"media") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":69},"end":{"line":11,"column":118}}})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sizes") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":12,"column":55}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"srcSet") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":108}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":109},"end":{"line":13,"column":46}}})) != null ? stack1 : "")
    + ">\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"dataSrc") : depth0), depth0))
    + "\" ";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " media=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"media") : depth0), depth0))
    + "\" ";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " sizes=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"sizes") : depth0), depth0))
    + "\" ";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " srcSet=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"srcSet") : depth0), depth0))
    + "\" ";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " type=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" ";
},"20":function(container,depth0,helpers,partials,data) {
    return "loading=\"lazy\" ";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-image-v2-onload=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"onLoadCallback") || (depth0 != null ? lookupProperty(depth0,"onLoadCallback") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"onLoadCallback","hash":{},"data":data,"loc":{"start":{"line":18,"column":50},"end":{"line":18,"column":68}}}) : helper)))
    + "\"";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-image-v2-fallbackimage=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fallbackImage") || (depth0 != null ? lookupProperty(depth0,"fallbackImage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fallbackImage","hash":{},"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":73}}}) : helper)))
    + "\"";
},"26":function(container,depth0,helpers,partials,data) {
    return "lazyload";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"setVar")||(depth0 && lookupProperty(depth0,"setVar"))||alias2).call(alias1,"uniqueSelector",(lookupProperty(helpers,"getUniqueSelector")||(depth0 && lookupProperty(depth0,"getUniqueSelector"))||alias2).call(alias1,{"name":"getUniqueSelector","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":45}}}),{"name":"setVar","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":47}}}))
    + "\n<div\n  class=\"image-wrapper "
    + alias3(((helper = (helper = lookupProperty(helpers,"uniqueSelector") || (depth0 != null ? lookupProperty(depth0,"uniqueSelector") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"uniqueSelector","hash":{},"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":3,"column":41}}}) : helper)))
    + " multi-image-fit"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fitWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":125}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cssSelectors") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":125},"end":{"line":3,"column":171}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"unique-id") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":171},"end":{"line":3,"column":211}}})) != null ? stack1 : "")
    + "\"\n  itemscope itemtype=\"http://schema.org/ImageObject\"\n  data-multi-image-data-string=\""
    + alias3((lookupProperty(helpers,"stringifyImagesObject")||(depth0 && lookupProperty(depth0,"stringifyImagesObject"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"stringifyImagesObject","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":64}}}))
    + "\" data-multi-image-id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"uniqueSelector") || (depth0 != null ? lookupProperty(depth0,"uniqueSelector") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"uniqueSelector","hash":{},"data":data,"loc":{"start":{"line":5,"column":87},"end":{"line":5,"column":105}}}) : helper)))
    + "\">\n  <meta itemprop=\"url\" content=\""
    + alias3((lookupProperty(helpers,"getNoscriptUrl")||(depth0 && lookupProperty(depth0,"getNoscriptUrl"))||alias2).call(alias1,undefined,(depth0 != null ? lookupProperty(depth0,"images") : depth0),(depth0 != null ? lookupProperty(depth0,"noscriptUrl") : depth0),true,{"name":"getNoscriptUrl","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":84}}}))
    + "\">\n  <picture>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "    <img "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loadingLazy") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":9},"end":{"line":17,"column":50}}})) != null ? stack1 : "")
    + " itemprop=\"image\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onLoadCallback") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":76}}})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fallbackImage") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":6},"end":{"line":19,"column":81}}})) != null ? stack1 : "")
    + "\n      class=\"js-image image "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loadingLazy") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":62}}})) != null ? stack1 : "")
    + "\" decoding=\"sync\" title=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":20,"column":87},"end":{"line":20,"column":98}}}) : helper))) != null ? stack1 : "")
    + "\" alt=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"altText") || (depth0 != null ? lookupProperty(depth0,"altText") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"altText","hash":{},"data":data,"loc":{"start":{"line":20,"column":105},"end":{"line":20,"column":118}}}) : helper))) != null ? stack1 : "")
    + "\"\n      src=\""
    + alias3((lookupProperty(helpers,"getNoscriptUrl")||(depth0 && lookupProperty(depth0,"getNoscriptUrl"))||alias2).call(alias1,undefined,(depth0 != null ? lookupProperty(depth0,"images") : depth0),(depth0 != null ? lookupProperty(depth0,"noscriptUrl") : depth0),true,{"name":"getNoscriptUrl","hash":{},"data":data,"loc":{"start":{"line":21,"column":11},"end":{"line":21,"column":63}}}))
    + "\">\n  </picture>\n</div>\n"
    + alias3((lookupProperty(helpers,"setStyleElement")||(depth0 && lookupProperty(depth0,"setStyleElement"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"uniqueSelector") : depth0),(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"setStyleElement","hash":{},"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":24,"column":41}}}))
    + "\n";
},"useData":true});
Handlebars.partials['components-content-image_v2-multi_image'] = svs.content.image_v2.templates.multi_image;
})(svs, Handlebars);


 })(window);